import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = `${environment.apiUrl}/v1/location`;

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    constructor(
        private http: HttpClient
    ) { }

    getCountries() {
        return this.http.get<any[]>(`${baseUrl}/countries`);
    }
    getStates(countryId: String) {
        return this.http.post<any[]>(`${baseUrl}/states`, { countryId });
    }
    getCities(countryId: String, stateId: String) {
        return this.http.post<any[]>(`${baseUrl}/cities`, { countryId: countryId, stateId: stateId });
    }

}