import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Group } from 'src/app/_models';
import { AccountService } from './account.service';

const baseUrl = `${environment.apiUrl}/v1/groups`;

@Injectable({ providedIn: 'root' })
export class GroupService {
    activeGroup: any;
    group$ = new Subject();
    groups$ = new Subject();
    account = this.accountService.accountValue;
    private selectedGroup: BehaviorSubject<Group>;

    constructor(
        private http: HttpClient,
        private accountService: AccountService,
    ) {
    }

    // public get groupValue(): Group {
    //     return this.selectedGroup.value;
    // }

    // public set groupValue(group: Group) {
    //     this.selectedGroup.next(group);
    // }


    getAll(user?: string, includeFirends?: boolean, status?: string) {
        let query = "?user=" + user
        if (includeFirends) {
            query = query + '&includeFirends=' + includeFirends;
            return this.http.get<Group[]>(`${baseUrl}` + query);
        } else if (!includeFirends && status) {
            query = query + '&status=' + status;
            return this.http.get<Group[]>(`${baseUrl}` + query);
        } else if (!includeFirends && user) {
            query = query + '&user=' + user;
            return this.http.get<Group[]>(`${baseUrl}` + query);
        } else {
            return this.http.get<Group[]>(`${baseUrl}`);
        }
    }

    create(group: any) {
        return this.http.post<Group>(`${baseUrl}`, group)
            .pipe(map(group => {
                return group
            }))
    }
    getOneById(id: String) {
        return this.http.get<Group>(`${baseUrl}/${id}`)
            .pipe(map(group => {
                this.activeGroup = group;
                return group
            }));
    }

    addTournament(groupId, tournamentId: any) {
        return this.http.post<Group>(`${baseUrl}/` + groupId + '/tournaments', { "tournamentId": tournamentId })
            .pipe(map(group => {
                this.activeGroup = group;
                group = { ...this.activeGroup, ...group };
                return group
            }))
    }


    add(groupId: String, players: any[]) {
        return this.http.post<Group>(`${baseUrl}/` + groupId + '/players', { "players": players })
            .pipe(map(group => {
                this.activeGroup = group;
                group = { ...this.activeGroup, ...group };
                return group
            }))
    }

    join(groupId: String) {
        return this.http.patch<Group>(`${baseUrl}/` + groupId + '/join', { "empty": "object" })
            .pipe(map(group => {
                this.activeGroup = group;
                return group
            }))
    }

    kick(groupId: String, participantId: String) {
        return this.http.patch<Group>(`${baseUrl}/` + groupId + '/kick/' + participantId, { "empty": "object" })
            .pipe(map(group => {
                this.activeGroup = group;
                return group
            }))
    }

    checkOptions(group) {
        group.options = []
        if (this.account) {
            let participant = group.players.find(p => p.player.id === this.account.id);

            if (participant) {
                if (participant.isAdmin) {
                    group.isAdmin = true
                    group.options.push({
                        name: "Invite",
                        long: "Invite participants",
                        action: "invite",
                        icon: 'person-add-outline',
                    })
                }
                // Participant & status open
                if (participant.status == 'open') {
                    group.invitation = true
                    group.options.push({
                        name: "Join",
                        long: "Join this group",
                        action: "join",
                        icon: 'checkmark-outline',
                    })
                    group.options.push({
                        name: "Decline",
                        long: "Decline invitation",
                        action: "decline",
                        icon: '',
                        color: 'light'
                    })

                    // Participant & status confirmed
                } else if (participant.status == 'confirmed') {
                    group.invitation = false
                    group.options.push({
                        name: "Leave",
                        long: "Leave this tournament",
                        action: "leave",
                        icon: ''
                    })
                }


            } else {

            }
        }
        return group
    }

}