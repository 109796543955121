import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/environments/environment';
import { Tournament } from 'src/app/_models';
import { AccountService } from './account.service';

const baseUrl = `${environment.apiUrl}/v1/tournaments`;

@Injectable({ providedIn: 'root' })
export class TournamentService {
    activeTournament: any;
    tournament$ = new Subject();
    tournaments$ = new Subject();
    account = this.accountService.accountValue;
    private selectedTournament: BehaviorSubject<Tournament>;
    constructor(
        private accountService: AccountService,
        private http: HttpClient,
        private translate: TranslateService
    ) {
        this.tournament$.next(false)
        this.tournaments$.next(false)
    }

    public get tournamentValue(): Tournament {
        return this.selectedTournament.value;
    }

    public set tournamentValue(tournament: Tournament) {
        this.selectedTournament.next(tournament);
    }

    getAll(user?: string, includeFirends?: boolean, status?: string) {
        let query = "?user=" + user
        if (includeFirends) {
            query = query + '&includeFirends=' + includeFirends;
            return this.http.get<Tournament[]>(`${baseUrl}` + query);
        } else if (!includeFirends && status) {
            query = query + '&status=' + status;
            return this.http.get<Tournament[]>(`${baseUrl}` + query);
        } else if (!includeFirends && user) {
            query = query + '&user=' + user;
            return this.http.get<Tournament[]>(`${baseUrl}` + query);
        } else {
            return this.http.get<Tournament[]>(`${baseUrl}`);
        }
    }

    join(tournamentId: String) {
        return this.http.patch<Tournament>(`${baseUrl}/` + tournamentId + '/join', { "empty": "object" })
            .pipe(map(tournament => {
                this.activeTournament = tournament;
                return tournament
            }))
    }

    kick(tournamentId: String, participantId: String) {
        return this.http.patch<Tournament>(`${baseUrl}/` + tournamentId + '/kick/' + participantId, { "empty": "object" })
            .pipe(map(tournament => {
                this.activeTournament = tournament;
                return tournament
            }))
    }

    add(tournamentId: String, players: any[]) {
        return this.http.post<Tournament>(`${baseUrl}/` + tournamentId + '/players', { "players": players })
            .pipe(map(tournament => {
                this.activeTournament = tournament;
                tournament = { ...this.activeTournament, ...tournament };
                return tournament
            }))
    }

    addRound(league: any) {
        return this.http.post<Tournament>(`${baseUrl}/` + league._id + '/addRound', { "empty": "object" })
            .pipe(map(tournament => {
                this.activeTournament = tournament;
                tournament = { ...this.activeTournament, ...tournament };
                return tournament
            }))
    }

    getOneById(id: String) {
        return this.http.get<Tournament>(`${baseUrl}/${id}`)
            .pipe(map(tournament => {
                this.activeTournament = tournament;
                return tournament
            }));
    }

    upsert(league: any) {
        return this.update(league);
    }

    update(tournament: any) {
        return this.http.patch<any>(`${baseUrl}/` + tournament._id, tournament)
            .pipe(map(tournament => {
                return tournament
            }))
    }
    start(tournament: any) {
        return this.http.post<any>(`${baseUrl}/` + tournament?._id + '/status', { "status": "active", "seeding": this.activeTournament.seeding })
            .pipe(map(tournament => {
                // this.activeTournament = tournament;
                return tournament
            }))
    }

    startKO(tournament: any){
        return this.http.post<any>(`${baseUrl}/` + tournament?._id + '/status', { "status": "startKO" })
        .pipe(map(tournament => {
            // this.activeTournament = tournament;
            return tournament
        }))
    }

    close(tournamentId: String) {
        return this.http.post<any>(`${baseUrl}/` + tournamentId + '/status', { "status": "completed" })
            .pipe(map(league => {
                return league
            }))
    }

    create(tournament: any) {
        return this.http.post<Tournament>(`${baseUrl}`, tournament)
            .pipe(map(league => {
                return league
            }))
    }

    delete(tournamentId) {
        return this.http.delete(`${baseUrl}/` + tournamentId)
            .pipe(map(leagues => {
                return leagues
            }))
    }

    checkOptions(tournament) {
        tournament.options = []
        if (this.account) {
            let openCounter = 0;
            if (tournament.status != 'ready') {
              for (let tri = 0; tri < tournament.tournamentRound.length; tri++) {
                for (let gri = 0; gri < tournament.tournamentRound[tri].gameRound.length; gri++) {
                  for (let gi = 0; gi < tournament.tournamentRound[tri].gameRound[gri].games.length; gi++) {
                    if (tournament.tournamentRound[tri].gameRound[gri].games[gi].status === 'open') openCounter++;
                  }
                }
              }
            }
            let participant = tournament.players.find(p => p.player.id === this.account.id);
            this.translate.get('TOURNAMENTS.OPTIONS').subscribe((options: string) => {
                if (participant) {
                    //participant of game!
                    if (tournament.status == 'ready') {
                        // tournament is ready
                        if (participant.status == 'open') {
                            tournament.options.push({
                                name: options["ACCEPT"]['NAME'],
                                long: options["ACCEPT"]['LONG'],
                                action: "join",
                                icon: 'checkmark-outline',
                                fill: 'solid',
                                color: 'primary'
                            })
                            tournament.options.push({
                                name: options["DECLINE"]['NAME'],
                                long: options["DECLINE"]['LONG'],
                                action: "decline",
                                icon: 'close-outline',
                                fill: 'solid',
                                color: 'light'

                            })
                        } else if (participant.status == 'confirmed' && !participant.isAdmin) {
                            tournament.options.push({
                                name: options["LEAVE"]['NAME'],
                                long: options["LEAVE"]['LONG'],
                                action: "leave",
                                icon: '',
                                fill: 'outline',
                                color: 'danger'
                            })
                        }
                        if (participant.isAdmin) {
                            tournament.options.push({
                                name: options["START"]['NAME'],
                                long: options["START"]['LONG'],
                                action: "start",
                                icon: '',
                                fill: 'solid',
                                color: 'primary'

                            })
                            tournament.options.push({
                                name: options["DELETE"]['NAME'],
                                long: options["DELETE"]['LONG'],
                                action: "delete",
                                icon: '',
                                fill: 'outline',
                                color: 'danger'

                            })
                        }

                    } else if (tournament.status == 'active') {
                        // tournament is active
                        if (participant.isAdmin) {
                            if(tournament.tournamentType == 'league'){
                                tournament.options.push({
                                    name: options["ADD"]['NAME'],
                                    long: options["ADD"]['LONG'],
                                    action: "add",
                                    icon: 'add-outline',
                                    color: 'light',
                                    fill: 'solid',
                                })
                            }
                            if(tournament.tournamentType == 'league' && openCounter == 0){
                                tournament.options.push({
                                    name: options["STARTKO"]['NAME'],
                                    long: options["STARTKO"]['LONG'],
                                    action: "startKO",
                                    icon: null,
                                    color: 'light',
                                    fill: 'solid',
                                })
                            }
                            if (openCounter == 0) {
                                tournament.options.push({
                                    name: options["END"]['NAME'],
                                    long: options["END"]['LONG'],
                                    action: "end",
                                    icon: 'medal-outline',
                                    color: 'primary',
                                    fill: 'solid',
                                })
                            }
                        }
                    }
                    else if (tournament.status == 'completed') {
                        //completed tournaments
                    }
                    tournament.showOptions = true;
                } else {
                    //not a participant of tournament!
                    // todo ? when confirmed: react ? 
                }

            })
        }
        return tournament
    }

}