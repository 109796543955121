import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Plugins } from '@capacitor/core';
import { environment } from '../environments/environment';
import { NavController, Platform, AlertController } from '@ionic/angular';
const { SplashScreen, Storage, Network } = Plugins;
import { TranslateService } from "@ngx-translate/core";
// import { OneSignal } from '@ionic-native/onesignal/ngx';

import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    // private oneSignal: OneSignal,
    private alertCtrl: AlertController
  ) {
    // this.initializeApp();
    // this.OneSignalInit();
    // this.setupPush();
    translate.setDefaultLang('en');
    translate.use(translate.getBrowserLang());
  }
  location: Location

  // Call this function when your app starts
  async OneSignalInit(): Promise<void> {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    await OneSignal.setAppId("6c97bdd1-7634-429d-aa97-f1aa16f4e391");
    await OneSignal.setNotificationOpenedHandler(function (jsonData) {
    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    await OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.OneSignalInit();
      } else {
      }
    });
  }

  ngOnInit() {
    localStorage.setItem('lang', this.translate.getBrowserLang())
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    else {
      console.log("Environment: ", environment)
    }
  }

  ngAfterViewInit() {
    this.platform.ready().finally(() => setTimeout(() => SplashScreen.hide(), 1000));
  }
}