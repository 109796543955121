import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Account } from 'src/app/_models';
import { AvatarOptions } from '../components/avatar/src/public-api';
import { Platform, ToastController } from '@ionic/angular';

import OneSignal from 'onesignal-cordova-plugin';


const baseUrl = `${environment.apiUrl}/accounts`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    profile$ = new Subject();


    constructor(
        private http: HttpClient,
        private platform: Platform,
        private toastController: ToastController
    ) {
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
        this.profile$.next(false)
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${baseUrl}/authenticate`, { email, password }, { withCredentials: true })
            .pipe(
                map((account: Account) => {
                    this.subscribeNotification(account.id)
                    account = { ...this.accountValue, ...account };
                    account.avatar = new AvatarOptions(account.avatar)
                    this.accountSubject.next(account);
                    this.startRefreshTokenTimer();
                    return account;
                }));
    }

    subscribeNotification(playerId) {
        this.platform.ready().then(async () => {
            if (this.platform.is('cordova')) {
                OneSignal.setAppId("6c97bdd1-7634-429d-aa97-f1aa16f4e391");
                OneSignal.setExternalUserId(playerId, async (results) => {
                    // const toast = await this.toastController.create({
                    //     message: 'Success!',
                    //     duration: 1500,
                    //     position: 'bottom'
                    // });
                    // await toast.present();
                })
            } else {
                //not cordova
            }
        });
    }

    logout() {
        return this.http.post<any>(`${baseUrl}/revoke-token`, { empty: "string" }, { withCredentials: true })
            .pipe(
                map((message: any) => {
                    this.stopRefreshTokenTimer();
                    this.accountSubject.next(null);
                    return message;
                }));
    }

    refreshToken() {
        return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
            .pipe(
                map(
                    (account: Account) => {
                        this.subscribeNotification(account.id)
                        account.avatar = new AvatarOptions(account.avatar)
                        this.accountSubject.next(account);
                        this.startRefreshTokenTimer();
                        return account;
                    },
                    (err) => {
                    })
            );
    }

    addFriend(id: string) {
        return this.http.post<any>(`${baseUrl}/${this.accountSubject.value.id}/addFriend`, { "id": id })
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    removeFriend(id: string) {
        return this.http.post<any>(`${baseUrl}/${this.accountSubject.value.id}/removeFriend`, { "id": id })
            .pipe(map((account: any) => {
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email`, { token });
    }

    forgotPassword(email: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { email });
    }

    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }

    getAll(all?: boolean, search?: string): Observable<Account[]> {
        if (all) {
            return this.http.get<Account[]>((`${baseUrl}?all=true`));
        } else if (search) {
            return this.http.get<Account[]>(`${baseUrl}?search=` + search);
        } else if (!search) {
            return this.http.get<Account[]>(`${baseUrl}?search=`);
        } else {
            return this.http.get<Account[]>(`${baseUrl}`);
        }
    }

    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/${id}`)
            .pipe(map((account: any) => {
                account.avatar = new AvatarOptions(account.avatar)
                account = { ...this.accountValue, ...account };
                return account;
            }));
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }

    update(id, params) {
        return this.http.post(`${baseUrl}/${id}`, params)
            .pipe(map((account: any) => {
                account = { ...this.accountValue, ...account };
                this.accountSubject.next(account);
                this.startRefreshTokenTimer();
                return account;
            }));
    }

    // delete(id: string) {
    //     return this.http.delete(`${baseUrl}/${id}`)
    //         .pipe(finalize(() => {
    //             // auto logout if the logged in account was deleted
    //             if (id === this.accountValue.id)
    //                 this.logout();
    //         }));
    // }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}