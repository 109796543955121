import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Game } from 'src/app/_models';
import { AccountService } from '.';
import { TranslateService } from '@ngx-translate/core';

const baseUrl = `${environment.apiUrl}/v1/games`;


@Injectable({ providedIn: 'root' })
export class GameService {
    game: Game;
    subject$ = new Subject();
    account = this.accountService.accountValue;
    nextSteps: string;

    constructor(
        private http: HttpClient,
        private accountService: AccountService,
        private translate: TranslateService
    ) {
        this.subject$.next(false);
        this.translate.get('GAMES.STATUS').subscribe((nexSteps: string) => {
            this.nextSteps = nexSteps
        })
    }

    create(players: any) {
        return this.http.post(`${baseUrl}`, players);
    }

    getAll(user?: string, includeFirends?: boolean, status?: string) {
        let query = ""
        if (user) {
            query = "?user=" + user
            if (includeFirends) {
                query = query + '&includeFirends=' + includeFirends;
                return this.http.get<Game[]>(`${baseUrl}` + query);
            } else if (!includeFirends && status) {
                query = query + '&status=' + status;
                return this.http.get<Game[]>(`${baseUrl}` + query);
            } else if (!includeFirends) {
                return this.http.get<Game[]>(`${baseUrl}` + query);
            }
        } else {
            return this.http.get<Game[]>(`${baseUrl}`);
        }
    }

    getById(id: string) {
        return this.http.get<Game>(`${baseUrl}/${id}`)
            .pipe(map((game: any) => {
                game = { ...this.game, ...game };
                return game;
            }));
    }

    confirm(game: any) {
        return this.http.post(`${baseUrl}/${game._id}/confirm`, game);
    }

    decline(game: any) {
        return this.http.post(`${baseUrl}/${game._id}/decline`, game);
    }

    //todo: better handle the response; allg ames? none? 
    delete(game: any) {
        return this.http.delete(`${baseUrl}/${game._id}`);
    }

    undo(game: any) {
        return this.http.post(`${baseUrl}/${game._id}/undo`, game);
    }

    cancelUndo(game: any) {
        return this.http.post(`${baseUrl}/${game._id}/cancelUndo`, game);

    }

    setResult(game: any) {
        return this.http.post(`${baseUrl}/${game._id}/result`, game.players);
    }

    setReaction(game: any, reaction: any) {
        return this.http.post(`${baseUrl}/${game._id}/reactions`, reaction);
    }

    update(game: any) {
        return this.http.patch<any>(`${baseUrl}/${game._id}`, game)
            .pipe(map(game => {
                return game
            }))
    }

    checkOption(game) {
        let participant = game.players.find(p => p.player.id === this.account?.id);
        for (let i = 0; i < game.players.length; i++) {
            if (game.players[i].isDisruptor) {
                game.warning = true;
            }
        }
        game.options = []
        game.showOptions = false;
        game.nextStep = ""
        this.translate.get('GAMES.OPTIONS').subscribe((options: string) => {
            if (game.status == 'open') {
                if (participant) {
                    //open and participant
                    if (participant.scorePoints == null) {
                        // resultat noch nicht gesetzt
                        game.options.push({
                            name: options["CONFIRM"]['NAME'],
                            long: options["CONFIRM"]['LONG'],
                            action: "confirm",
                            icon: 'checkmark-outline',
                            color: 'success',
                            sliding: false,
                            footer: true
                        })
                        if (!game.tournamentId) {
                            //kein tournament
                            game.options.push({
                                name: options["DELETE"]['NAME'],
                                long: options["DELETE"]['LONG'],
                                action: "delete",
                                icon: 'trash-outline',
                                color: 'danger',
                                sliding: true,
                                footer: true
                            })
                        }
                        game.nextStep = this.nextSteps["ENTER"];
                        game.showOptions = true;
                    } else if (typeof participant.scorePoints == "number" && participant.confirmedResult == null) {
                        //resultat ist gesetzt, participant hat noch nicht confirmed
                        game.options.push({
                            name: options["CONFIRM"]['NAME'],
                            long: options["CONFIRM"]['LONG'],
                            action: "confirm",
                            icon: 'checkmark-outline',
                            color: 'success',
                            sliding: true,
                            footer: true
                        })
                        game.options.push({
                            name: options["DECLINE"]['NAME'],
                            long: options["DECLINE"]['LONG'],
                            action: 'decline',
                            icon: 'close-outline',
                            color: 'medium',
                            sliding: true,
                            footer: true
                        })
                        game.nextStep = this.nextSteps["CONFIRM"];
                        game.showOptions = true;

                    } else if (typeof participant.scorePoints == "number" && participant.confirmedResult != null) {
                        //result set and already confirmed
                        game.options.push({
                            name: options["DECLINE"]['NAME'],
                            long: options["DECLINE"]['LONG'],
                            action: 'decline',
                            icon: 'close-outline',
                            color: 'medium',
                            sliding: true,
                            footer: true
                        })
                        game.nextStep = this.nextSteps["WAITINGCONFIRM"];
                        game.showOptions = true;
                    }
                } else {
                    //Open but no participant
                    game.nextStep = this.nextSteps["OPEN"];
                }
            } else {
                // confirmed
                if (participant) {
                    if (game?.tournamentId || game.tournamentId?.status != "completed") {
                        //wenn tournament nicht completed ist oder gar kein tournament hat...
                        if (!participant.isDisruptor) {
                            game.options.push({
                                name: options["UNDO"]['NAME'],
                                long: options["UNDO"]['LONG'],
                                action: "undo",
                                icon: 'arrow-undo-outline',
                                color: 'medium',
                                sliding: true,
                                footer: true
                            })
                            game.showOptions = true;
                        }

                        if (game.warning && participant) {
                            game.options.push({
                                name: options["CANCELUNDO"]['NAME'],
                                long: options["CANCELUNDO"]['LONG'],
                                action: "cancelUndo",
                                icon: 'close-outline',
                                color: 'medium',
                                sliding: false,
                                footer: true
                            })
                            game.showOptions = true;
                        }
                    }
                }
            }
            if (game.tournamentId && this.account) {
                game.options.push({
                    name: options["TOURNAMENT"]['NAME'],
                    long: options["TOURNAMENT"]['LONG'],
                    action: "tournament",
                    icon: 'trophy-outline',
                    sliding: false,
                    footer: false
                })
                game.showOptions = true;
            }
        })
        return game
    }
}